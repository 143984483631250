import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import Typist from 'react-typist';
import { SectionContainer, GlobalFont } from '../ComponentsElements';
import { Space } from 'antd';

export const Intro = styled(SectionContainer)`
  padding-top: 20vh;
`;

export const ProfilePicture = styled(Image)`
  display: flex;
  margin-bottom: 40px;
  border: 5px solid black;
`;

export const Name = styled.h1`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: 30px;
  display: flex;
  justify-content: center;
`;

export const AboutTypist = styled(Typist)`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: 40px;
  display: flex;
`;

export const CenteredSpace = styled(Space)`
  width: 100%;
  align-items: center;
`;
