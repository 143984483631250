import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

export const GlobalFont = {
  name: 'Comfortaa',
  category: 'cursive',
};

export const FontSizes = {
  title: '30px',
  body: '18px',
  comingSoon: '80px',
};

export const Title = styled.h3`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: ${FontSizes.title};
  position: relative;
  display: flex;
  justify-content: center;
`;

export const BodyText = styled.p`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: ${FontSizes.body};
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const SectionContainer = styled(Container)`
  padding-bottom: 50px;
`;
