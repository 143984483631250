import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ComingSoon from './components/ComingSoon';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

var scroll = require('scroll');
var page = require('scroll-doc')();
var ease = require('ease-component');

const ProfilePicture = styled(Image)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 12;
`;

const comingSoon = true;

class App extends React.Component {
  renderMain() {
    return (
      <div>
        <About />
        <Projects />
        <Contact />
      </div>
    );
  }

  renderProjects() {
    <div></div>;
  }

  handleScroll = (e) => {
    e.preventDefault();
    const main = this.main.current;
    scroll.top(page, main.offsetTop, {
      duration: 1500,
      ease: ease.inOutSince,
    });
  };

  render() {
    return (
      <div className="HeaderBackground">
        {comingSoon ? (
          <ComingSoon></ComingSoon>
        ) : (
          <Router>
            <Navbar handleScroll={this.handleScroll} />
            <Switch>
              <Route path="/" exact component={() => this.renderMain()} />
              <Route render={() => <h1>Page not found</h1>} />
            </Switch>
          </Router>
        )}
      </div>
    );
  }
}

export default App;
