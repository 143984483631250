import React from 'react';
import {
  ProjectCardHeader,
  ProjectCardDescription,
} from './ProjectCardElements';
import { Card, Icon, Button, Container } from 'semantic-ui-react';

function ProjectCard(props) {
  return (
    <Container>
      <Card centered>
        <Card.Content>
          <ProjectCardHeader>
            <Icon name={props.icon} /> {props.name}
          </ProjectCardHeader>
          <ProjectCardDescription>{props.description}</ProjectCardDescription>
        </Card.Content>
        <Card.Content extra>
          <Button
            animated="vertical"
            fluid
            onClick={() => window.open(props.link, '_blank')}
          >
            <Button.Content visible>
              <Icon name="github" />
            </Button.Content>
            <Button.Content hidden>Source Code</Button.Content>
          </Button>
        </Card.Content>
      </Card>
    </Container>
  );
}

export default ProjectCard;
