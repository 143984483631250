import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import { GlobalFont } from '../ComponentsElements';

export const ProjectCard = styled(Card)`
  padding: 5px;
`;

export const ProjectCardHeader = styled.p`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: 17px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
`;

export const ProjectCardDescription = styled.p`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: 13px;
  text-align: center;
  padding-right: 6px;
  padding-left: 6px;
`;
