import React from 'react';
import {
  Nav,
  NavLink,
  NavLogo,
  Bars,
  NavMenu,
  StickyWrapper,
} from './NavbarElements';
import Pdf from '../../data/Resume.pdf';

class Navbar extends React.Component {
  onResumeClick() {
    window.open(Pdf);
  }

  onNavClick(elementID) {
    var element = document.getElementById(elementID);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return (
      <StickyWrapper>
        <Nav>
          <NavLogo to="/">Michel Chalfoun</NavLogo>
          <Bars />
          <NavMenu>
            <NavLink onClick={() => this.onNavClick('about')}>About</NavLink>
            <NavLink onClick={() => this.onNavClick('projects')}>
              Projects
            </NavLink>
            <NavLink onClick={this.onResumeClick}>Resume</NavLink>
            <NavLink onClick={() => this.onNavClick('contact')}>
              Contact
            </NavLink>
          </NavMenu>
        </Nav>
      </StickyWrapper>
    );
  }
}

export default Navbar;
