import React, { useState, useEffect } from 'react';
import {
  Intro,
  ProfilePicture,
  AboutTypist,
  Name,
  CenteredSpace,
} from './AboutElements';
import { Title, BodyText, SectionContainer } from '../ComponentsElements';
import { Divider } from 'semantic-ui-react';
import { Timeline, Card } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import bio from '../../data/bio.js';

function About() {
  const [count, setCount] = useState(1);
  useEffect(() => {
    setCount(1);
  }, [count]);

  let greetings = ['Hello', 'Bonjour', 'مرحبا', 'Hola'];

  const timelineData = [
    {
      dot: <ClockCircleOutlined className="timeline-clock-icon" />,
      color: 'red',
      label: 'August 2022',
      content: (
        <Card bordered={false} type="inner" style={{ width: 300 }}>
          <p>Meta</p>
        </Card>
      ),
    },
    {
      dot: <ClockCircleOutlined className="timeline-clock-icon" />,
      color: 'red',
      label: 'June 2021',
      content: (
        <Card bordered={false} type="inner" style={{ width: 300 }}>
          <p>Citi Group</p>
        </Card>
      ),
    },
  ];

  return (
    <>
      <Intro id="about">
        <ProfilePicture src="/profile.jpg" size="medium" circular centered />
        <Name>Michel Chalfoun</Name>
        <BodyText>
          {count ? (
            <AboutTypist
              avgTypingDelay={100}
              cursor={{ show: false }}
              onTypingDone={() => setCount(0)}
            >
              {greetings.map((greeting, index) => {
                return (
                  <div key={index}>
                    <BodyText>{greeting}</BodyText>
                    <AboutTypist.Backspace
                      count={greeting.length}
                      delay={2000}
                    />
                  </div>
                );
              })}
            </AboutTypist>
          ) : (
            ''
          )}
          ! I'm a Software Engineer based in Seatle, Washington.
        </BodyText>
      </Intro>
      <SectionContainer>
        <Title>A little bit about me</Title>
        <Divider />
        <BodyText textAlign="center">{bio}</BodyText>
      </SectionContainer>
      <CenteredSpace direction="vertical">
        <Timeline mode={'alternate'}>
          {timelineData.map((item, index) => {
            return (
              <Timeline.Item
                dot={item.dot}
                color={item.color}
                label={item.label}
                style={{
                  width: '50rem',
                }}
              >
                {item.content}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </CenteredSpace>
    </>
  );
}

export default About;
