import React from 'react';
import { Title } from '../ComponentsElements';
import { Divider, Icon, Button } from 'semantic-ui-react';
import {
  ContactTextContainer,
  SocialsContainer,
  ContactText,
} from './ContactElements';
import { SectionContainer } from '../ComponentsElements';

const socials = {
  facebook: 'https://www.facebook.com/chalfounmichel',
  linkedin: 'https://www.linkedin.com/in/michelelchalfoun/',
  email: 'mailto:mikachalfoun@gmail.com',
};

const Contact = () => {
  return (
    <>
      <SectionContainer>
        <Title id="contact">Contact Me</Title>
        <Divider />
        <ContactTextContainer>
          <ContactText>
            I'm not currently looking for a position, but my inbox is always
            open. Wether it's about this website, one of projects or just to say
            hi, I'll do my best to get back to you!
          </ContactText>
        </ContactTextContainer>
        <SocialsContainer>
          <Button
            circular
            color="linkedin"
            icon="linkedin"
            onClick={() => window.open(socials.linkedin, '_blank')}
          />
          <Button
            circular
            color="facebook"
            icon="facebook"
            onClick={() => window.open(socials.facebook, '_blank')}
          />
          <Button
            circular
            icon="mail"
            onClick={() => (window.location = socials.email)}
          />
        </SocialsContainer>
      </SectionContainer>
    </>
  );
};

export default Contact;
