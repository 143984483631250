import React from 'react';
import { ComingSoonBackground, ComingSoonTitle } from './ComingSoonElements';

function ComingSoon() {
  return (
    <>
      <ComingSoonBackground>
        <ComingSoonTitle>Coming Soon</ComingSoonTitle>
      </ComingSoonBackground>
    </>
  );
}

export default ComingSoon;
