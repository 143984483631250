import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import Typist from 'react-typist';
import styled from 'styled-components';
import { GlobalFont, FontSizes } from '../ComponentsElements';

export const StickyWrapper = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100vw;
  background-color: #212121;
  border-bottom: 2px solid darkblue;
`;

export const Nav = styled.nav`
  height: 75px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1250px) / 2);
`;

export const NavLink = styled.b`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: ${FontSizes.body};
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;

export const NavLogo = styled(NavLink)`
  font-size: 40px;
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
