import styled from 'styled-components';
import { BodyText } from '../ComponentsElements';

export const ContactTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactText = styled(BodyText)`
  width: 510px;
`;

export const SocialsContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
