import React from 'react';
import { SectionContainer } from '../ComponentsElements';
import { Title } from '../ComponentsElements';
import { Divider, Grid } from 'semantic-ui-react';
import ProjectCard from '../ProjectCard';

const Projects = () => {
  return (
    <>
      <SectionContainer>
        <Title id="projects">Notable Projects</Title>
        <Divider />
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <ProjectCard
                icon="terminal"
                name="Complete Custom Shell"
                description="A command interpreter for a Korn shell-like command language in C
                using Lex and Yacc running under Unix. It parses command lines and
                executes the appropriate command(s). The core of the shell consists
                of simple commands, pipes, I/O redirection, environment variables,
                aliases, pathname searching and wild-carding."
                link="https://github.com/michelchalfoun/Nutshell"
              />
            </Grid.Column>
            <Grid.Column>
              <ProjectCard
                icon="mobile alternate"
                name="Clubfinity"
                description="[Clubfinity Description]"
                link="https://gitlab.com/ufsec/clubfinity"
              />
            </Grid.Column>
            <Grid.Column>
              <ProjectCard
                icon="asl"
                name="Handsy"
                description="American Sign Language learning game that utilizes Unity and C Sharp 
                to teach american sign language using two main methods: educational 
                and competitive. The hand motions are captured using the LEAP Motion Controller."
                link="https://github.com/amills12/SignLanguageGame"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ProjectCard
                icon="flask"
                name="The GLADE Research Lab Backend"
                description="Backend API that formats as well as supplies VR and AR applications the 
                data needed from the sensors in a University of Florida Siemens power plant. It is 
                built on the Node.jS and Express.jS stack while the data is stored on a MongoDB database."
                link="https://github.com/amills12/SignLanguageGame"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionContainer>
    </>
  );
};

export default Projects;
