import styled from 'styled-components';
import { FontSizes, GlobalFont } from '../ComponentsElements';

export const ComingSoonBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://images.pexels.com/photos/459277/pexels-photo-459277.jpeg);
  background-size: cover;
`;

export const ComingSoonTitle = styled.h3`
  font-family: ${GlobalFont.name}, ${GlobalFont.category};
  font-size: ${FontSizes.comingSoon};
`;
